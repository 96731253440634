import Navigation from "./Navigation";
import Fade from 'react-reveal/Fade';

const Header = () => {
    return (
        <section id="header">
            <Navigation />

            <div className="container">

                <Fade bottom>
                    <div className="main-container">
                        {/*
                        <div className="title">
                            <h2>SOCCERMANIA</h2>
                        </div>
                        */}

                        <div className="headercontent">
                            <p className="heading">SOCCERMANIA </p>
                            <p>
                                GET REWARDED FOR YOUR SOCCER SKILLS.
                            </p>
                        </div>

                        <div className="btn-container">
                            <div className="btn">
                                <a href="https://www.bitmart.com/" target="_blank" rel="noreferrer"><h5>BUY SOCCERMANIA</h5></a>
                            </div>
                        </div>

                    </div>
                </Fade>

            </div>
        </section>
    );
}

export default Header;