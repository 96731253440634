import { useEffect, useState } from 'react';
import image1 from '../assets/image1.png';
import Fade from 'react-reveal/Fade';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const Vision = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation runs only once
    });

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (inView && !hasAnimated) {
            setHasAnimated(true);
        }
    }, [inView, hasAnimated]);
    return (
        <section id="about">
            <div className="container">
                <div className="main-aboutcontainer">
                    <div className="aboutus">
                        <div className="leftabout-container">

                            <Fade bottom>
                                <div className="about-text">
                                    <h2>Welcome to our thrilling play-to-earn soccer game!</h2>
                                    <p>
                                        Are you ready to get rewarded for your soccer skills? Do you want to showcase your love and passion for soccer and earn money while playing?
                                        Soccermania presents an opportunity for soccer lovers and enthusiasts to step into our immersive world, where playing the game earns you real rewards. Whether you're a soccer pro or a passionate fan, our game offers an unmatched experience that combines the excitement of soccer with the opportunity to earn valuable prizes.
                                    </p>
                                </div>
                            </Fade>

                        </div>
                        <div className="rightabout-container">
                            <div className="aboutimg">
                                <motion.div
                                    ref={ref}
                                    initial={{ opacity: 0, scale: 2.5 }}
                                    animate={hasAnimated ? { opacity: 1, scale: 1 } : {}}
                                    transition={{
                                        duration: 0.8,
                                        delay: 0.5,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                >
                                    {hasAnimated && <img src={image1} alt="Your Alt Text" />}
                                </motion.div>
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Vision;