import './App.css';
import './Responsiveness.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Vision from './components/Vision';
import Gameplay from './components/Gameplay';
import Compete from './components/Compete';
import Development from './components/Development';
import SocialConnection from './components/Social-connection';
import Partnership from './components/Partnership';
import SlideImages from './components/SlideImages';
import CopyRights from './components/CopyRights';
import GameTrailer from './components/GameTrailer';

function App() {
  return (
    <div className="body">
      <Header />
      <Vision />
      <Gameplay />
      <Compete />
      <Development />
      <SocialConnection />
      <Partnership />
      <GameTrailer />
      <SlideImages />
      <CopyRights />
    </div>
  );
}

export default App;
