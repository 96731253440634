import Fade from 'react-reveal/Fade';

const SocialConnection = () => {
    return (
        <section id="SocialConnection">
            <div className="container">
                <div className="socialcontainer">

                    <Fade bottom>
                        <div className="social-title">
                            <h2 style={{ color: '#DE691C' }}>Build <br /><span>Social connections</span></h2>
                        </div>
                    </Fade>

                    <div className="center-container">

                        <Fade bottom>
                            <div className="social-content">
                                <p>
                                    Connect with fellow soccer enthusiasts in our vibrant community.
                                    Join teams, organize tournaments, chat, and share interesting
                                    moments with fellow soccer enthusiasts. Soccer is an interesting
                                    game, and our game ensures you never miss out on the interesting
                                    atmosphere it presents.
                                </p>
                            </div>
                        </Fade>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default SocialConnection;