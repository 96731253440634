import { useEffect, useState } from 'react';
import image1 from '../assets/image3.png';
import Fade from 'react-reveal/Fade';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const Compete = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation runs only once
    });

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (inView && !hasAnimated) {
            setHasAnimated(true);
        }
    }, [inView, hasAnimated]);
    return (
        <section id="compete">
            <div className="container">
                <div className="main-competecontainer">
                    <div className="compete">
                        <div className="leftcompete-container">

                            <Fade bottom>
                                <div className="compete-text">
                                    <h2>Compete with other soccer lovers worldwide</h2>
                                    <p>
                                        Compete against players worldwide in thrilling real-time matches. Demonstrate your strategic
                                        thinking, quick reflexes, and teamwork to claim victory. Each win brings you closer to unlocking
                                        rare rewards and earning recognition in the virtual soccer community.
                                    </p>
                                </div>
                            </Fade>

                        </div>
                        <div className="rightcompete-container">
                            <div className="competeimg">
                                <motion.div
                                    ref={ref}
                                    initial={{ opacity: 0, scale: 2.5 }}
                                    animate={hasAnimated ? { opacity: 1, scale: 1 } : {}}
                                    transition={{
                                        duration: 0.8,
                                        delay: 0.5,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                >
                                    {hasAnimated && <img src={image1} alt="Your Alt Text" />}
                                </motion.div>
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Compete;