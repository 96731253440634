import { useEffect, useState } from 'react';
import image1 from '../assets/image4.png';
import Fade from 'react-reveal/Fade';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';


const Development = () => {

    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation runs only once
    });

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (inView && !hasAnimated) {
            setHasAnimated(true);
        }
    }, [inView, hasAnimated]);

    return (
        <section id="Development">
            <div className="container">
                <div className="main-development-container">
                    <div className="development">
                        <div className="leftdevelopment-container">
                            <div className="developmentimg">
                                <motion.div
                                    ref={ref}
                                    initial={{ opacity: 0, scale: 2.5 }}
                                    animate={hasAnimated ? { opacity: 1, scale: 1 } : {}}
                                    transition={{
                                        duration: 0.8,
                                        delay: 0.5,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                >
                                    {hasAnimated && <img src={image1} alt="Your Alt Text" />}
                                </motion.div>
                            </div>
                        </div>
                        <div className="rightdevelopment-container">

                            <Fade bottom>
                                <div className="development-text">
                                    <h2>Development of Skills</h2>
                                    <p>
                                        Hone your soccer abilities, knowledge of soccer through realistic gameplay mechanics.
                                        Train your players, enhance their attributes, and build the dream team you've always
                                        thought of having.
                                    </p>
                                </div>
                            </Fade>

                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Development