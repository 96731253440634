import img1 from '../assets/imageleft.png';
import img2 from '../assets/imagecenter.png';
import img3 from '../assets/imageright.png';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";

const Data = [
    {
        photo: img1,
    },
    {
        photo: img2,
    },
    {
        photo: img3,
    },
];

const SlideImages = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation runs only once
    });

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (inView && !hasAnimated) {
            setHasAnimated(true);
        }
    }, [inView, hasAnimated]);
    return (
        <section id="SlideImages">
            <div className="container">
                <div className="main-SlideImagescontainer">
                    <div className="image-container">
                        {Data.map((item, index) => (
                            <div key={index} className="inline-div">
                                <motion.div
                                    ref={ref}
                                    initial={{ opacity: 0, scale: 2.5 }}
                                    animate={hasAnimated ? { opacity: 1, scale: 1 } : {}}
                                    transition={{
                                        duration: 0.8,
                                        delay: 0.5,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                >
                                    {hasAnimated && <img src={item.photo} alt="Your Alt Text" />}
                                </motion.div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SlideImages;