import image1 from '../assets/frame.png';
import Fade from 'react-reveal/Fade';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";

const GameTrailer = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation runs only once
    });

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (inView && !hasAnimated) {
            setHasAnimated(true);
        }
    }, [inView, hasAnimated]);
    return (
        <section id="game-trailer">
            <div className="container">
                <div className="main-game-trailer">
                    <Fade bottom>
                        <div className="game-trailer-title">
                            <h2>GAME TRAILER</h2>
                        </div>
                    </Fade>

                    <div className="game-image">
                        <motion.div
                            ref={ref}
                            initial={{ opacity: 0, scale: 2.5 }}
                            animate={hasAnimated ? { opacity: 1, scale: 1 } : {}}
                            transition={{
                                duration: 0.8,
                                delay: 0.5,
                                ease: [0, 0.71, 0.2, 1.01],
                            }}
                        >
                            {hasAnimated && <img src={image1} alt="Game trailer" />}
                        </motion.div>

                        {/*<video className="lazy" loop autoPlay muted src={video} />*/}

                        <div className="game">
                            <Fade bottom>
                                <div className="game-btn">
                                    <a href='#' target="_blank" rel="noopener noreferrer"><button>COMING SOON</button></a>
                                </div>
                            </Fade>
                        </div>

                    </div>
                </div>
            </div>
        </section >
    );
}

export default GameTrailer;