import Fade from 'react-reveal/Fade';

const Partnership = () => {
    return (
        <section id="Partnership">
            <div className="container">
                <div className="Partnershipcontainer">

                    <Fade bottom>
                        <div className="Partnership-title">
                            <h2 style={{ color: '#DE691C' }}>Exclusive<br /><span>Partnerships</span></h2>
                        </div>
                    </Fade>

                    <div className="partnershipcenter-container">

                        <Fade bottom>
                            <div className="Partnership-content">
                                <p>
                                    Soccermania is a play-to-earn soccer game. We collaborate with renowned
                                    brands and organizations to bring you exclusive rewards. Imagine
                                    receiving VIP access to events, autographed soccer merchandise, or even
                                    trips to watch your favorite teams live – all by playing our game.
                                </p>
                                <p>
                                    Are you prepared to embrace a game that rewards your soccer passion
                                    like never before? Join us now and step onto the virtual soccer field. Lace
                                    up your virtual boots and embark on an incredible journey of play and rewards!
                                    Soccermania, get rewarded for your soccer skills.
                                </p>
                            </div>
                        </Fade>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partnership;